import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Layout from '../components/layout';
import { getUser } from '../auth';
import { EXTENSION_ID } from '../config';

export default function CheckoutSuccess() {
  useEffect(() => {
    getUser({ forceRefresh: true });
    const runtime = chrome?.runtime ?? browser.runtime;
    if (runtime) {
      runtime.sendMessage(EXTENSION_ID, { action: 'checkout', payload: { success: true }});
    }
  }, []);

  return (
    <Layout noIndex={true}>
      <div className='root' style={{ paddingTop: 80 }}>
        <Container maxWidth='lg'>
          <Paper variant='outlined'>
            <div style={{ textAlign: 'center', padding: 24 }}>
              <CheckCircleOutlineIcon style={{ fontSize: 120, color: '#2175b4' }} />
              <h1>Thank you for your purchase!</h1>
              <p>A subscription has been added to your account</p>
              <p>Sign in to the extension or reload the manager to activate backup & sync</p>
            </div>
          </Paper>
        </Container>
      </div>
    </Layout>
  );
}
